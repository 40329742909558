/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface UserStoreDto {
  /** Preferred theme */
  theme: string;
}

export interface UserSettingsDto {
  /** User settings id */
  id: number;
  /** User settings store */
  store: UserStoreDto;
}

export interface UserProfileDto {
  /** User profile id */
  id: number;
  /** User first name */
  firstName: string;
  /** User last name */
  lastName: string;
  /** User timezone */
  timezone?: string | null;
  /** User country */
  country?: string | null;
  /** User city */
  city?: string | null;
  /** User phone */
  phone?: string | null;
}

export interface MeResponseDto {
  /** User's settings */
  settings: UserSettingsDto;
  /** User's profile */
  profile: UserProfileDto;
  /** User's role */
  role: string;
  /** User's email */
  email: string;
  /** User's id */
  id: number;
}

export interface AudienceTemplateFileUploadDto {
  /** The main audience template identifier */
  audienceTemplateId: number;
  /** The audience type of the file */
  audienceType: AudienceTemplateFileUploadDtoAudienceTypeEnum;
  /**
   * The audience file to be uploaded
   * @format binary
   */
  audienceFile: File;
}

export interface BucketWorkFileUploadDto {
  /**
   * The bucket identifier
   * @format uuid
   */
  remoteBucketId: string;
  /**
   * The work file to be uploaded
   * @format binary
   */
  workFile: File;
}

export enum NpiSourceEnum {
  LHK = 0,
  DMD = 1,
}

export enum NpiDestinationEnum {
  GENERAL = 0,
  SWOOP = 1,
}

export interface NpiDto {
  number?: number;
  encryptedNumber?: string;
  source: NpiSourceEnum;
  destination: NpiDestinationEnum;
}

export interface SourceMetadata {
  /** Title of the source */
  title?: string;
}

export interface RecommendBodyDto {
  /**
   * Encrypted NPI numbers
   * @default []
   */
  npis?: NpiDto[];
  /** Metadata about the source */
  sourceMetadata?: SourceMetadata;
  /** Start time */
  startTime?: number;
}

export enum LinkingTypeEnum {
  INTERNAL = 0,
  EXTERNAL = 1,
}

export interface PublicRecommendationItemDto {
  /**
   * Recommendation id
   * @format uuid
   */
  id: string;
  /** Recommendation title */
  title: string;
  /** Recommendation subtitle */
  subtitle?:
    | string
    | {
        type?: string;
        content?: string;
      }
    | {
        type?: string;
        content?: string;
        /** @format url */
        url?: string;
      }
    | null;
  /**
   * Recommendation URL
   * @format url
   */
  url: string;
  /**
   * Impression tracking URL
   * @format url
   */
  impressionUrl: string;
  /**
   * Click tracking URL
   * @format url
   */
  clickUrl: string;
  /** Campaign Id */
  campaignId: string;
  /** Linking type */
  linkingType: LinkingTypeEnum;
  /** Recommendation authors */
  authors?: string | null;
  /** Publication name */
  publicationName?: string | null;
  /** Publication abbreviated name */
  publicationAbbreviatedName?: string | null;
  /** Publication date */
  publicationDate?: string | null;
  /** Publication id */
  publicationId?: string | null;
}

export interface AdSetIdentificationResponseDto {
  /**
   * The ad set identifier
   * @format uuid
   */
  identifier: string;
  /** The current time of the request */
  currentTime: number;
}

export interface NpiDetectorsConfig {
  /** Npi detector url */
  url: string;
}

export enum SwoopIntegrationTypeEnum {
  NONE = 0,
  DEFAULT = 1,
  CALLBACK_ONLY = 2,
  PASSTHROUGH = 3,
}

export interface PublicAdSettingsDto {
  /** Swoop Id */
  swoopId?: string | null;
  /** Swoop integration type */
  swoopIntegrationType?: SwoopIntegrationTypeEnum | null;
  /** Swoop url */
  swoopScriptUrl?: string | null;
  /**
   * Npi sources
   * @default []
   */
  npiSources: NpiSourceEnum[] | null;
  /**
   * Npi detection status
   * @default false
   */
  npiDetectionEnabled?: boolean | null;
  /** Npi detectors config */
  npiDetectors?: {
    '0'?: NpiDetectorsConfig;
    '1'?: NpiDetectorsConfig;
  } | null;
}

export interface PublicAdSetDto {
  /** Ad Set identifier */
  identifier: string;
  /** Ad Set settings */
  settings: PublicAdSettingsDto;
}

export enum AdSlotPositionInjectTypeEnum {
  NONE = 0,
  BEFORE_END = 1,
  AFTER_BEGIN = 2,
  REPLACE = 3,
}

export enum AdSlotFormatEnum {
  AUTO = 1,
  LEADERBOARD = 2,
  LARGE_LEADERBOARD = 3,
  XL_LEADERBOARD = 4,
  TV_SPOT_LEADERBOARD = 5,
  BILLBOARD = 6,
  WIDE_SKYSCRAPER = 7,
  SKYSCRAPER = 8,
  HALF_PAGE_SKYSCRAPER = 9,
  MEDIUM_RECTANGLE = 10,
  SQUARE_RECTANGLE = 11,
  HALF_PAGE_RECTANGLE = 12,
  XL_RECTANGLE = 13,
  MEDIUM_SPLASH = 14,
  SMALL_SPLASH = 15,
  SMARTPHONE_BANNER = 16,
  SUPER_LEADERBOARD = 17,
  PORTRAIT = 18,
  MOBILEPHONEINTERSTITIAL1 = 19,
  MOBILEPHONEINTERSTITIAL2 = 20,
  MOBILEPHONEINTERSTITIAL3 = 21,
  FEATURE_PHONE_SMALL_BANNER = 22,
  FEATURE_PHONE_MEDIUM_BANNER = 23,
  FEATURE_PHONE_LARGE_BANNER = 24,
}

export interface AdSlotSettingStyleDto {
  heading?: {
    fontSize?: string | null;
  };
  outerContainer?: {
    fontSize?: string | null;
  };
  container?: {
    fontSize?: string | null;
  };
  list?: {
    fontSize?: string | null;
  };
  item?: {
    fontSize?: string | null;
  };
  itemLink?: {
    fontSize?: string | null;
  };
  itemTitle?: {
    fontSize?: string | null;
  };
  itemSubtitle?: {
    fontSize?: string | null;
  };
  itemMeta?: {
    fontSize?: string | null;
  };
  itemAuthors?: {
    fontSize?: string | null;
  };
  footer?: {
    fontSize?: string | null;
  };
  footerLeft?: {
    fontSize?: string | null;
  };
  footerRight?: {
    fontSize?: string | null;
  };
}

export interface LinkProperties {
  target?: string | null;
  rel?: string | null;
  referrerPolicy?: string | null;
}

export interface LinkPropertiesWithHref {
  target?: string | null;
  rel?: string | null;
  referrerPolicy?: string | null;
  href?: string | null;
}

export interface AdSlotSettingLinkPropertiesDto {
  internal?: LinkProperties | null;
  external?: LinkProperties | null;
  subtitle?: LinkProperties | null;
  privacyPolicy?: LinkPropertiesWithHref | null;
  analytics?: LinkPropertiesWithHref | null;
  brand?: LinkPropertiesWithHref | null;
}

export interface PublicAdSlotSettingsDto {
  /** Css position selector */
  positionSelector?: string | null;
  /** Css position inject type */
  positionInjectType?: AdSlotPositionInjectTypeEnum | null;
  /** Max number of columns */
  maxNumberOfColumns?: number | null;
  /** Format */
  format: AdSlotFormatEnum;
  /** Dataset Id */
  datasetId?: string | null;
  /** Link cloaking */
  linkCloaking: boolean;
  /** Heading text */
  headingText?: string | null;
  /** CSS Style */
  style?: AdSlotSettingStyleDto | null;
  /** Privacy Policy URL */
  privacyPolicyUrl?: string | null;
  /** Link Properties */
  linkProperties?: AdSlotSettingLinkPropertiesDto | null;
}

export interface AdSlotConstraintConstraintDto {
  /** Constraint any support */
  any: boolean;
  /** Constraint values */
  values?: string[] | null;
}

export enum AdSlotConstraintTypeEnum {
  GEO = 0,
  NPI = 1,
  IP = 2,
}

export interface PublicAdSlotConstraintDto {
  /** Constraint type */
  type: AdSlotConstraintTypeEnum;
  /** Constraint */
  constraint: AdSlotConstraintConstraintDto;
}

export interface PublicAdSlotDto {
  /** Ad Slot identifier */
  identifier: string;
  /** Ad Slot settings */
  settings: PublicAdSlotSettingsDto;
  /** Ad Slot constraints */
  constraints: PublicAdSlotConstraintDto[];
}

export interface PublicSettingsDto {
  adSet: PublicAdSetDto;
  /** Ad Slots */
  adSlots: PublicAdSlotDto[];
}

export interface ProcessNpiBodyDto {
  /** Encrypted NPI number */
  encryptedNpi?: string;
  /** Aim ID */
  aimId?: number;
  /** Npi source enum */
  source: NpiSourceEnum;
}

export enum EventTypeEnum {
  AD_SLOT_ITEM_CLICK = 0,
  AD_SLOT_ITEM_LOAD = 1,
  AD_SLOT_ITEM_IMPRESSION = 2,
  AD_SLOT_VIEW = 3,
  AD_SLOT_NO_ITEMS = 4,
  AD_SET_LOAD = 5,
  AD_SLOT_LOAD = 6,
}

export interface EventRegisterBodyDto {
  /** Event type */
  eventType: EventTypeEnum;
  /** Payload. Encrypted MetaData that is stored in AdSlot item's URL */
  encryptedPayload?: string;
  /** Start time */
  startTime?: number;
  /** Placement index */
  placementIndex?: number;
  /**
   * Encrypted NPI numbers
   * @default []
   */
  npis?: NpiDto[];
}

/** The audience type of the file */
export enum AudienceTemplateFileUploadDtoAudienceTypeEnum {
  Value1 = 1,
  Value2 = 2,
  Value4 = 4,
  Value8 = 8,
  Value16 = 16,
}

export enum RecommendationsControllerRecommendParamsXRevopsSourceTypeEnum {
  Value0 = 0,
  Value1 = 1,
  Value2 = 2,
}

export enum AdSetControllerGetAdSetIdentifierParamsXRevopsSourceTypeEnum {
  Value0 = 0,
  Value1 = 1,
  Value2 = 2,
}

export enum AdSetControllerGetSettingsParamsXRevopsSourceTypeEnum {
  Value0 = 0,
  Value1 = 1,
  Value2 = 2,
}

export enum AdSetControllerProcessNpiParamsXRevopsSourceTypeEnum {
  Value0 = 0,
  Value1 = 1,
  Value2 = 2,
}

export enum EventsControllerRegisterEventParamsXRevopsSourceTypeEnum {
  Value0 = 0,
  Value1 = 1,
  Value2 = 2,
}

export enum OpenControllerOpenRecItemParamsXRevopsSourceTypeEnum {
  Value0 = 0,
  Value1 = 1,
  Value2 = 2,
}

export enum OpenShareControllerOpenRecItemParamsXRevopsSourceTypeEnum {
  Value0 = 0,
  Value1 = 1,
  Value2 = 2,
}
